/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link, useBreakpointValue } from '@chakra-ui/react';
import { useContextualRouting } from 'next-use-contextual-routing';

/* Internal Imports */
/* Components */
import { refineUrlDomain } from '@components/Utility/utils';
import config from '@config';

/* Services */
// import { consoleLog } from '@components/Utility/Shared/SharedService';

/**
 * Renders the Upcoming ProgramCard component
 *
 * @param {object} sectionContent - Content from API.
 * @param {number} number - Big image to show.
 * @param {string} region - Current User Country.
 * @param {string} lang - Current User Language.
 * @param {object} apiType - Api Type of component
 * @returns {ReactElement} Upcoming Program Card component
 */

const UpcomingProgramCard = ({
  sectionContent,
  number,
  count,
  apiType,
  region,
  lang,
}) => {
  // let programReady = (process.env.SSR_MODE || process.env.SSR_MODE === '1' || process.env.NEXT_PUBLIC_SSR_MODE === '1' || config.ENV_NAME === 'dev') ? true : false; // ? if program api ready make it true
  const { makeContextualHref } = useContextualRouting();
  const variant = useBreakpointValue({ md: 'md' });

  let content =
    sectionContent &&
    (number === 0
      ? sectionContent[count.toString()]
      : sectionContent[number.toString()]);

  const handleClick = content => {
    let tempProgUrl = '';
    let progUrl = '';
    if (apiType === 'macro') {
      tempProgUrl = `${
        config.BASE_PATH
      }/${region}/${lang}/redirect-to-site?link=${encodeURI(
        content?.program_url
      )}`;
      progUrl = refineUrlDomain(tempProgUrl);
    } else {
      progUrl =
        variant === 'md'
          ? makeContextualHref({ id: content?.program_id })
          : refineUrlDomain(content?.program_url);
    }
    return progUrl;
  };

  const handleAsLink = content => {
    let progUrl = '';
    if (apiType === 'macro') {
      progUrl = `${
        config.BASE_PATH
      }/${region}/${lang}/redirect-to-site?link=${encodeURI(
        content?.program_url
      )}`;
    } else {
      progUrl = `/${region}/${lang}/program-details?id=${content.program_id}`;
    }
    return progUrl;
  };

  return content ? (
    <NextLink
      href={handleClick(content)}
      as={handleAsLink(content)}
      passHref
      scroll={false}
      shallow
      legacyBehavior
    >
      <Link
        _hover={{
          textDecoration: 'none',
        }}
        target={apiType === 'macro' ? '_blank' : ''}
      >
        <Box
          position="static"
          boxShadow="0 0 16px 0 rgb(0 0 0 / 15%)"
          minHeight="261px"
          // marginBottom="3vw"
          backgroundColor="White"
          textAlign="left"
          color="black"
          margin={{ base: '0 auto', md: 'inherit' }}
        >
          <Box backgroundColor="rgb(234 227 212)" h="202px">
            <Box
              fontFamily="FedraSansStd-A-medium"
              minHeight="125px"
              backgroundColor="rgb(174 69 17)"
              color="rgb(255 255 255)"
              fontSize="20px"
              lineHeight="1.1"
              padding="20px 16px 20px 16px"
              clipPath="polygon(0 0, 100% 0, 100% 100%, 0 90%)"
            >
              {content?.title}
            </Box>

            <Box
              fontFamily="FedraSansStd-medium"
              fontSize="16px"
              padding="0 15px 15px"
            >
              {content?.date}
              {content?.language?.toLowerCase() !== 'unspecified' && (
                <Box fontFamily="FedraSansStd-book" fontSize="14px">
                  {toTitleCase(content?.language)}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            fontFamily="FedraSansStd-book"
            fontSize="14px"
            padding="15px 0 15px 15px"
          >
            {content?.address ? `${content?.address}, ` : ''}
            {content?.city ? `${content?.city}, ` : ''}
            {content?.state ? `${content?.state}, ` : ''}
            {content?.country ? `${content?.country}` : ''}
          </Box>
        </Box>
      </Link>
    </NextLink>
  ) : (
    ''
  );
};

// helpers
const toTitleCase = str =>
  str?.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );

export default UpcomingProgramCard;
